import React, { Component } from "react";

import QuestionLogin from "./QuestionLogin.js";
import ImageDisplay from "./ImageDisplay.js";

import "../pages/New-Game.css";
import './QuestionPage.css';
import { socket } from "../../client-socket.js";
import { get, post } from "../../utilities.js";
import SynchronizedTextArea from "./SynchronizedTextArea";

// PROPS:
//   gameCode: String,
//   questionNumber: number,
//   teamName: String
//   questions: [String],
//   time: Number,
//   points: [Number],
//   password: String
//   nextQuestion: a function that makes the game go to the next question
class QuestionPage extends Component {
  constructor(props) {
    super(props);
    this.needsImageLoad = true;
    // Initialize Default State
    this.state = {
      authorized: props.authorized || false,
      answers: new Array(this.props.questions.length).fill(""),
      time: this.props.time,
      reset: false,
      images: []
    };

    socket.on(`nextQ:${this.props.teamName}:${this.props.gameCode}`, () => {
      if (!this.state.authorized) {
        const intervalId = setInterval(this.decreaseTimer, 1000);
        this.setState({ authorized: true, intervalId: intervalId });
      }
    });

    socket.on(`proctResetTime:${this.props.teamName}:${this.props.gameCode}`, () => {
      this.setState({
        time: this.props.time,
        answers: new Array(this.props.questions.length).fill(""),
        reset: true,
      });
    });

    socket.on(`proctResetTeam:${this.props.gameCode}:${this.props.teamName}`, () => {
      clearInterval(this.state.intervalId);
      this.setState({
        authorized: false,
        answers: new Array(this.props.questions.length).fill(''),
        time: this.props.time,
        images: []
      });
      this.props.doc.unsubscribe();
      this.needsImageLoad = true;
      this.props.onReset();
    })
  }

  getImages() {
    if (!this.needsImageLoad) return;
    this.needsImageLoad = false;
    get("/api/images/", {
      gameCode: this.props.gameCode,
      questionNum: this.props.questionNumber,
    }).then((results) => {
      this.setState({
        images: results
      });
    });
  }

  decreaseTimer = () => {
    if (this.state.time === 0) {
      this.handleOutOfTime();
    }
    if (this.state.authorized) {
      this.setState({
        time: this.state.time - 1,
        reset: false,
      });
    }
  };

  handleOutOfTime = () => {
    clearInterval(this.state.intervalId);
    let answers = [];
    Object.keys(this.props.doc.data).sort().forEach(key => {
      answers.push(this.props.doc.data[key]);
    });
    this.props.doc.unsubscribe();
    post("/api/student-answers/", {
      gameCode: this.props.gameCode,
      questionNum: this.props.questionNumber,
      teamName: this.props.teamName,
      content: answers,
    });
    this.setState({
      authorized: false,
      time: this.props.nextQuestionTime * 60,
      answers: new Array(this.props.questions.length).fill(""),
      images: []
    });
    this.needsImageLoad = true
    this.props.nextQuestion();
  };

  loggedIn = () => {
    const intervalId = setInterval(this.decreaseTimer, 1000);
    this.setState({ authorized: true, intervalId: intervalId });
    // this.setState({ authorized: true});

    post("/api/start-time/", {
      gameCode: this.props.gameCode,
      questionNum: this.props.questionNumber,
      teamName: this.props.teamName,
    });
  };

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  };

  render() {
    if (this.state.authorized === false) {
      return (
        <>
          <QuestionLogin
            password={this.props.password}
            name={this.props.name}
            gameCode={this.props.gameCode}
            questionNum={this.props.questionNumber}
            teamName={this.props.teamName}
            time={this.state.time / 60}
            onSuccess={this.loggedIn}
	    onOverride={this.props.onOverride}
          />
        </>
      );
    }

    if (this.needsImageLoad) {
      this.getImages();
    }

    let className = this.state.time > 45 ? 'normal' : this.state.time > 15 ? 'warning1' : this.state.time % 2 === 1 ? 'warning2a' : 'warning2b';
    let time = Math.floor(this.state.time / 60).toString(10) + ':' +
        (this.state.time % 60 < 10 ? '0' : '') + (this.state.time % 60);

    return (
      <>
        <p className="watermark">
          {new Array(2000).fill(this.props.teamName).join(' ')}
        </p>
        <h1> Question {String.fromCharCode(this.props.questionNumber - 1 + "A".charCodeAt(0))} </h1>
        <p className='timer'>Time remaining: <span className={className}>{time}</span></p>
        {this.props.questions.map((singleQuestion, i) => (
          <div key={`question-${i}`}>
            <h2> Part {i + 1} (worth {this.props.points[i]} points)</h2>
            {
              this.props.hideQuestions ? "" : (<>
              <ImageDisplay
                  gameCode={this.props.gameCode}
                  images={this.state.images.filter((elem) => {
                    return elem.partNum === i + 1;
                  })}
              />
              <p> Question: </p>
              {singleQuestion.split(/\r?\n/g).map(text => {
                return (
                <p>{text}</p>
                )
              })}
              </>)
            }
            <SynchronizedTextArea
                doc={this.props.doc}
                part={i}
            />
          </div>
        ))}
      </>
    );
  }
}

export default QuestionPage;
